import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: localStorage.language !== undefined ? localStorage.language : 'IN'
  },
  mutations: {
    setLang (language) {
      this.state.lang = language
    }
  },
  actions: {
  },
  modules: {
  }
})
