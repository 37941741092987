<template>
  <div class="home">
    <div class="home-header">
      <div class="wrapper-header-left">
        <transition enter-active-class="animated tada" >
          <h2 v-if="titleShow"><span> WELCOME </span></h2>
        </transition>
        <Waypoint :tag="'div'" @change="showTagline($event, 0)" />
        <transition enter-active-class="animated fadeInUp" >
          <h3 v-if="taglineShow[0]">
            <span v-if="$store.state.lang == 'IN'"> &emsp; Bagi pemilik bisnis atau usaha terutama di daerah jabodetabek (jakarta, bogor, depok, tangerang, dan bekasi), pasti membutuhkan jasa box packing dan cetak kemasan custom terbaik untuk packaging produknya. Gigihpack hadir sebagai perusahaan penyedia jasa konsultasi desain online dan cetak kemasan custom untuk berbagai jenis produk seperti kemasan kue & snack, kemasan makanan, kemasan produk (kesehatan, kecantikan, fashion, dan aksesoris). Spesialisasi kami adalah pada produksi paper packaging menggunakan berbagai jenis bahan kertas dengan teknologi digital atau offset printing untuk menghasilkan kemasan custom terbaik.</span>
            <span v-if="$store.state.lang == 'EN'"> &emsp; For business or business owners especially in the jabodetabek region (Jakarta, bogor, depok, tangerang, and bekasi), it undoubtedly needed the best custom box of packing and packaging printing for its products. Gigihpack presents asan online design consulting company and custom packaging materials for a variety of products such as cakes & snack packs, food packages, packaging products (health, beauty, fashion, and accessories). Our specialty is the production of paper presses using various types of paper materials with digital or offset printing to produce the best custom packaging.</span>
          </h3>
        </transition>
        <Waypoint :tag="'div'" @change="showTagline($event, 4)" />
        <transition enter-active-class="animated fadeInUp" >
          <h3 v-if="taglineShow[4]">
            <span v-if="$store.state.lang == 'IN'">&emsp; Gigihpack juga dikenal sebagai vendor packaging, percetakan packaging dan tempat printing packaging. Kemasan yang kami buat dicetak dengan teknik digital maupun offset printing dengan media kertas, sehingga hasil cetakan jelas, awet, dan lebih ramah lingkungan.</span>
            <span v-if="$store.state.lang == 'EN'">&emsp; The gigihpack is also known as the packaging vendor, the printing presses and the printing site. The materials we make are printed with digital techniques as well as offset printing with paper media, resulting in a clearer, durable, and more ecological printing.</span>
          </h3>
        </transition>
        <Waypoint :tag="'div'" @change="showTagline($event, 1)" />
        <transition enter-active-class="animated fadeInUp" >
          <h3 v-if="taglineShow[1]">
            <span v-if="$store.state.lang == 'IN'">&emsp; Kami melayani jasa cetak kemasan custom, dus kemasan makanan, dus packing, box nasi dan dus catering, cake box, kemasan produk, packaging box, kotak kemasan. Kemasan dan packaging box diproduksi dengan bahan kertas dan dapat dicetak dengan teknik sablon atau printing custom sesuai kebutuhan konsumen. Kotak kemasan produk biasa digunakan untuk berbagai jenis produk seperti sepatu, jam tangan, gift box, souvenir promosi, aksesoris, dan lain sebagainya.</span>
            <span v-if="$store.state.lang == 'EN'">&emsp; We serve custom packaging, cartons of food, cartons of packing, box of rice and box of catering, cake box, packaged products, packaged box, packaged box. Packing and engraving boxes are produced with paper materials and can be printed with sablon or printing custom according to consumer needs. Packaged products are commonly used for a variety of products like shoes, watches, gift boxes, promotional souvenirs, accessories, and so on.</span>
          </h3>
        </transition>
        <Waypoint :tag="'div'" @change="showTagline($event, 2)" />
        <transition enter-active-class="animated fadeInUp" >
          <h3 v-if="taglineShow[2]">
            <span v-if="$store.state.lang == 'IN'">&emsp; Jenis industri yang sering mempercayakan cetak kemasan custom di Gigihpack yaitu industri di bidang makanan (food and beverages), kesehatan, kecantikan, serta fashion dan aksesoris. Pada bidang industri makanan, kami dapat melayani cetak food box, food pail, paper food tray, lunch box, snack box, dus nasi kotak, kemasan catering, box brownies, gable box, hingga premium cake box. Di bidang industri kesehatan, kami telah memproduksi berbagai kemasan dus dan box seperti box masker, dus box gloves (sarung tangan), packaging box farmasi, hingga dus kemasan medical kit. Untuk bidang industri kosmetik, kami memproduksi kemasan packaging inner box parfum, kotak lipstik dan dus make up, kotak face mask, box spray, box skincare, hingga hardbox kosmetik premium. Sedangkan untuk bidang industri fashion dan aksesoris, kami dapat membuat kemasan baju, kotak jam tangan, box sarung, dus hijab, packaging mukena dan sajadah, hingga kemasan alat makan.</span>
            <span v-if="$store.state.lang == 'EN'">&emsp; The type of industry that often stores custom packaging printing in gigihpack is a food and beauty industry, health, beauty, and fashion and accessories. In the food pail, food pail, paper food tray, lunch box, lunch box, snack box, box rice boxes, catering packages, box brownies, box gable, to premium cake boxes. In the health industry, we have now produced cartons of cartons and boxes such as box masks, box gloves, pharmaceutical boxes, to cartons of medical kits. In the cosmetics industry, we produce packaged inner box fragrances, lipstick box and makeup boxes, face mask boxes, box spray, box skincare, to premium cosmetic hardbox. In the fashion industry and accessories, we have been able to make packing clothes, watch boxes, box gloves, cartons of hijab, packaging hijab and prayer mats, to packaged eating utensils.</span>
          </h3>
        </transition>
        <Waypoint :tag="'div'" @change="showTagline($event, 3)" />
        <transition enter-active-class="animated fadeInUp" >
          <h3 v-if="taglineShow[3]">
            <span v-if="$store.state.lang == 'IN'">&emsp; Kemasan makanan adalah produk yang paling banyak dicetak dalam hal kuantitas. Di isi lain, meningkatnya tren food delivery dan take away membuat kemasan / packaging makanan seperti food box, food pail, dan kardus gable box menjadi produk yang banyak dibutuhkan. Kami menyediakan berbagai jenis kemasan dan  box packaging custom untuk makanan seperti dus geprek, kemasan kebab,  box brownies, dus box donat, snack box, kemasan martabak & roti bakar, hingga premium cake seperti cupcake dan croffle.</span>
            <span v-if="$store.state.lang == 'EN'">&emsp; Packaging food is the most printed product in quantity. Elsewhere, the rising trend of food delivery and take away makes packaged foods such as the food box, the food pail, and the cardboard box become much needed. We provide a variety of packing items and box items custom for such items as cartons of geprek, kebab packs, boxes of brownies, boxes of donuts, snack boxes, martabak boxes & toast boxes, to premium cakes such as cupcakes and croffles.</span>
          </h3>
        </transition>
        <Waypoint :tag="'div'" @change="showTagline($event, 5)" />
        <transition enter-active-class="animated fadeInUp" >
          <h3 v-if="taglineShow[5]">
            <span v-if="$store.state.lang == 'IN'">&emsp; Gigihpack terus berkomitmen untuk mengembangkan produk kemasan berbahan kertas (paper packaging) ramah lingkungan. Beberapa jenis kertas yang digunakan dalam proses cetak packaging dan kemasan custom di antaranya adalah ivory, duplex/dupleks, karton, food grade paper, kraft, samson, linen, jasmine, hingga cardboard.</span>
            <span v-if="$store.state.lang == 'EN'">&emsp; Gigihpack continues to commit to developing green paper packaging products. Some types of paper used in packaging materials custom in the ivory, duplex/dupleks, cardboard, foodgrade paper, kraft, Samson, linen, jasmine, to cardboard.</span>
          </h3>
        </transition>
      </div>
    </div>
    <div class="wrepper-content-home">
      <div class="home-top">
        <h2><span>OUR PARTNERS</span></h2>
      </div>
      <div class="home-right">
        <vue-picture-swipe :items="[
          {src: getImage('image15.jpg'), thumbnail: getImage('image15.jpg'),w: 600,h: 600},
          {src: getImage('imagesalada.jpeg'), thumbnail: getImage('imagesalada.jpeg'),w: 900,h: 600},
          {src: getImage('image152.jpg'), thumbnail: getImage('image152.jpg'),w: 900,h: 600},
          {src: getImage('image153.jpg'), thumbnail: getImage('image153.jpg'),w: 600,h: 600},
          {src: getImage('image154.jpg'), thumbnail: getImage('image154.jpg'),w: 800,h: 600},
          {src: getImage('image155.jpg'), thumbnail: getImage('image155.jpg'),w: 900,h: 600},
          {src: getImage('image156.jpg'), thumbnail: getImage('image156.jpg'),w: 700,h: 600},
          {src: getImage('image157.jpg'), thumbnail: getImage('image157.jpg'),w: 600,h: 600},
          {src: getImage('image158.jpg'), thumbnail: getImage('image158.jpg'),w: 800,h: 600},
          {src: getImage('image159.jpg'), thumbnail: getImage('image159.jpg'),w: 800,h: 600},
          {src: getImage('image1510.jpg'), thumbnail: getImage('image1510.jpg'),w: 800,h: 600},
          {src: getImage('image15511.jpg'), thumbnail: getImage('image15511.jpg'),w: 800,h: 600}]">
        </vue-picture-swipe>
        <br />
        <it-button size="big" type="primary" pulse >And Many More !</it-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import VuePictureSwipe from 'vue-picture-swipe'

export default {
  name: 'Home',
  components: {
    Waypoint,
    VuePictureSwipe
  },
  data () {
    return {
      sItemA: false,
      titleShow: false,
      taglineShow: [false, false, false, false, false, false],
      mapShow: false,
      changeSecond: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.titleShow = true
    }, 100)
    setTimeout(() => {
      this.imgShow = true
    }, 500)
    console.log(this.$data.$lang)
  },
  methods: {
    getImage (imgName) {
      var image = require.context('../assets/image/', false)
      return image('./' + imgName)
    },
    showTagline (waypoint, no) {
      if ((waypoint.going === 'IN' || waypoint.going === 'OUT') && (waypoint.direction === 'UP' || waypoint.direction === 'DOWN')) {
        this.taglineShow[no] = true
      }
    }
  }
}
</script>
<style scoped>
  .home-item{
    position: relative;
    width: 100%;
  }
</style>
<style lang="scss">
  .home{
    position: relative;
    min-height: 90vh;
    padding: 2.5rem;
    text-align: left;
    background: #fff;
    border-radius: 1rem 1rem 0rem 0rem;
    .home-header{
      position: relative;
      background-color: #fff;
      overflow: hidden;
      border-radius: 0.8rem;
      margin: -2.5rem;
      background-attachment: fixed;
      background-position-y: 100px;
      background-repeat: no-repeat;
      background-size: 100%;
      transition: 700ms;
      overflow: hidden;
      min-height: 59vh;
      .wrapper-header-left{
        display: inline-grid;
        width: 100%;
      }
      h2{
        font-size: 3rem;
        font-family: LondonTwo;
        color: #644627;
        text-shadow: -1px -1px 0px #dedede;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4rem;
        span {
          background: #dedede;
          border-radius: .6rem;
          display: inline-flex;
          height: 1.5rem;
          line-height: 0px;
        }
      }
      h3{
        float: left;
        color: #4e4e4e;
        width: 80%;
        padding-left: 10rem;
        text-shadow: 0px 1px 2px #dedede;
        text-align: center;
      }
    }
    h2, h3, h4{
      color: #644627;
      text-shadow: 0px 1px 2px #fff;
      margin-bottom: .3rem;
      span{
        float: left;
      }
    }
    p {
      line-height: 1.3rem;
    }
    .it-btn{
      margin-right: auto;
      margin-left: auto;
    }
    .iconify{
        vertical-align: middle;
    }
    .wrepper-content-home{
      position: relative;
      width: 100%;
      margin-top: 6rem;
      overflow: hidden;
      .content-left{
        display: inline-block;
      }
      .content-right{
        display: flex;
        float: right;
        width: 50%;
        height: 450px;
        background-color: rgba(135, 92, 45,0.8);
        border-radius: 1rem;
        text-align: center;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
      }
      .home-top{
        display: inline-grid;
        width: 100%;
        h2{
          font-size: 2rem;
          font-family: LondonTwo;
          color: #644627;
          text-shadow: -1px -1px 0px #ededed;
          padding-left: 10rem;
          padding-right: 10rem;
          padding-top: 3rem;
          margin-bottom: 2rem;
          margin-left: auto;
          margin-right: auto;
          span {
            background: #ededed;
            border-radius: .6rem;
            display: inline-flex;
            height: 1rem;
            line-height: 0px;
          }
        }
        p{
          width: 80%;
          padding-left: 10rem;
          padding-right: 10rem;
          padding-bottom: 1.5rem;
        }
      }
      .home-right{
        display: inline-block;
        width: 80%;
        margin-left: 10%;
        border-radius: 1rem;
        text-align: center;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        .it-collapse{
          width: 95%;
          margin-left: auto;
          margin-right: 0px;
          margin-bottom: 1rem;
          text-align: left;
          .it-collapse-item-title{
            background-color:#644627;
            color: #fafbfd;
          }
        }
        .my-gallery{
          img{
            width: 250px;
            height: 250px;
            object-fit: contain;
            border-radius: 3px;
            transition: all 500ms;
            opacity: .9;
          }
          img:hover{
            height: 260px;
            opacity: 1;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 650px) {
    .home{
      .home-header{
        min-height: 50vh;
        h2{
          font-size: 2rem;
          margin-left: 27%;
        }
        h3{
          font-size: 1rem;
          width: 90%;
          margin-left: 0%;
          text-align: center;
          padding-left: 5%;
        }
      }
      .wrepper-content-home{
        margin: 0rem;
        margin-top: 4rem;
        .content-right{
          margin-right: 0rem;
          width: 100%;
          margin-top: 2rem;
        }
        .home-top{
          h2{
            padding-right: 0rem;
            padding-left: 10%;
            font-size: 1rem;
          }
        }
      }
    }
  }
</style>
