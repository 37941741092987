<template>
    <div>
        <item-group-button class="group-btn" :class="{'group-btn-active':groupBtnActive}" @menu-clicked="menuClicked"  />
        <label for="check">
            <input type="checkbox" id="check" ref="btnBurger" v-model="groupBtnActive" />
            <span></span>
            <span></span>
            <span></span>
        </label>
    </div>
</template>
<script>
import ItemGroupButton from './ItemGroupButton.vue'

export default {
  name: 'ItemBurgerButton',
  components: {
    ItemGroupButton
  },
  data () {
    return {
      groupBtnActive: false
    }
  },
  methods: {
    menuClicked () {
      this.$refs.btnBurger.click()
      this.$emit('page-changed')
    }
  }
}
</script>
<style scoped>
.group-btn{
  left: 100%;
  transition: 1s;
}
.group-btn-active{
  left: 0px;
}
label{
  display: flex;
  flex-direction:column;
  width:40px;
  cursor:pointer;
}
label span{
  background: #17954c;
  border-radius:10px;
  height:7px;
  margin:3px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
  box-shadow: 0.5px 0.5px 0px #6aedac;
}
span:nth-of-type(1){
  width:50%;
}
span:nth-of-type(2){
  width:100%;
}
span:nth-of-type(3){
  width:75%;
}
input[type="checkbox"]{
  display:none;
}
input[type="checkbox"]:checked ~ span:nth-of-type(1){
  transform-origin:bottom;
  transform:rotatez(45deg) translate(6px,0px)
}
input[type="checkbox"]:checked ~ span:nth-of-type(2){
  transform-origin:top;
  transform:rotatez(-45deg)
}
input[type="checkbox"]:checked ~ span:nth-of-type(3){
  transform-origin:bottom;
  width:50%;
  transform: translate(17px,-9px) rotatez(45deg);
}
</style>
