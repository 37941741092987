<template>
  <footer>
    <ul>
        <li class="li-item">
            <ul>
                <li><router-link to="/" class="wrapper-footer-logo"><img alt="gigihpack logo" src="../assets/gigihpack-logo.png"></router-link></li>
                <li><p>JL. Benteng Betawi No. 29 Tanah Tinggi, Batuceper, Tangerang, Banten. 15119</p></li>
                <!-- <li><p><strong> Copyright &copy; 2020 - {{ year }} </strong></p></li> -->
            </ul>
        </li>
        <li class="li-item">
            <ul>
                <li><h3>GIGIHPACK</h3></li>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/about">
                  <span v-if="$store.state.lang == 'IN'" >Tentang Kami</span>
                  <span v-if="$store.state.lang == 'EN'" >About Us</span>
                </router-link></li>
                <li><router-link to="/catalogue">
                  <span v-if="$store.state.lang == 'IN'" >Produk</span>
                  <span v-if="$store.state.lang == 'EN'" >Product</span>
                </router-link></li>
                <li><router-link to="/contact">
                  <span v-if="$store.state.lang == 'IN'" >Hubungi Kami</span>
                  <span v-if="$store.state.lang == 'EN'" >Call Us</span>
                </router-link></li>
            </ul>
        </li>
        <li class="li-item">
            <ul>
                <li><h3>Contact</h3></li>
                <li> +62 813-8306-5737 (Risa)</li>
                <li> +62 857-7745-4799 (Ruli)</li>
                <li> +62 859-5509-1222 (Putra)</li>
                <li>marketing@gigihpack.com</li>
            </ul>
        </li>
        <li class="li-item">
            <ul>
                <li><h3>Social Media</h3></li>
                <li><a href="https://web.facebook.com/profile.php?id=100076291816611" target="_blank"> <span class="iconify" data-icon="mdi:facebook"></span> gigihpack</a></li>
                <li><a href="https://www.instagram.com/hallogigihpack" target="_blank"> <span class="iconify" data-icon="mdi:instagram"></span> hallogigihpack</a></li>
            </ul>
        </li>
    </ul>
  </footer>
</template>
<script>
import { computed } from 'vue'

export default {
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
}
</script>
<style lang="scss">
  footer{
    position: relative;
    margin: 0px;
    width: 100%;
    height: auto;
    color: #fff;
    background-color: rgb(100,70,39);
    top: 86vh;
    padding: 1rem 0rem 1rem 0rem;
    text-align: left;
    img{
      vertical-align: top;
      width: 5rem;
    }
    .wrapper-footer-logo{
      display: block;
      width: 5rem;
      background-color: #fff;
      padding: 1.3rem;
      border-radius: 5rem;
      box-shadow: 0px 0px 16px #838140;
      filter: drop-shadow(0px 0px 3px #ffff);
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }
    ul {
      width: 82%;
      margin: auto;
      list-style: none;
      padding: 0rem 1.5rem 0rem 1rem;
    }
    ul li {
      display: inline-flex;
      margin-left: .5rem;
    }
    .li-item ul{
      list-style: none;
    }
    .li-item ul li{
      display: list-item;
      line-height: 1.5rem;
    }
    h3{
        font-family: "LondonTwo";
        text-transform: uppercase;
        line-height: 0rem;
    }
    p{
        max-width: 18rem;
        padding-top: .5rem;
    }
    a{
        color: #fff;
        &.router-link-exact-active {
            color: #d1d1d1;
        }
    }
    a:hover, a:active{
        color: #d1d1d1;
    }
    .iconify{
        vertical-align: middle;
    }
  }

  @media only screen and (max-width: 1040px) {
      footer{
        ul{
            margin:0px;
        }
      }
  }
  @media only screen and (max-width: 700px) {
      footer{
        ul{
            margin-bottom: 2rem;
        }
      }
  }
</style>
