<template>
  <app-header @page-changed="pageChanged" />
  <app-banner/>
  <div id="wrapper-content" ref="wrapperContent">
    <div class="wrapper-btn-wa-fixed" >
      <it-icon box box-color="#17954c" color="#fff" name="whatsapp" @click="openWa" />
    </div>
    <router-view/>
  </div>
  <app-footer/>
</template>
<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import AppBanner from './components/AppBanner.vue'

export default {
  components: {
    AppHeader,
    AppFooter,
    AppBanner
  },
  methods: {
    // onScroll () {
    //   // const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
    //   // const contentElement = document.getElementById('wrapper-content')
    //   // const wabtnElement = document.getElementById('wrapper-btn-wa')
    //   // if (currentScrollPosition < 0) {
    //   //   return
    //   // }
    //   // this.wrapperBtnWaScrolled = currentScrollPosition >= contentElement.offsetTop - wabtnElement.offsetTop
    // },
    pageChanged () {
      const el = this.$refs.wrapperContent
      const headerOffset = 95
      const elPosition = el.getBoundingClientRect().top
      const offsetPosition = elPosition + window.pageYOffset - headerOffset
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    },
    openWa () {
      window.open('https://wa.me/6281383065737/?text=Hallo%20GigihPack%2C%20saya%20ada%20kebutuhan%20food%20packaging%20bisa%20dibantu%3F', '_blank')
    }
  },
  beforeCreate () {
    setTimeout(() => {
      this.$Loading.update(30)
    }, 100)
    setTimeout(() => {
      this.$Loading.update(60)
    }, 200)
    setTimeout(() => {
      this.$Loading.update(90)
    }, 300)
  },
  mounted () {
    setTimeout(() => {
      this.$Loading.finish()
    }, 1000)
    // window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    // window.removeEventListener('scroll', this.onScroll)
  }
}
</script>
<style lang="scss">
@font-face {
  font-family: "LondonMM";
  src: local("LondonMM"),
   url(./assets/fonts/LondonMM.ttf) format("truetype");
}
@font-face {
  font-family: "LondonTwo";
  src: local("LondonTwo"),
   url(./assets/fonts/LondonTwo.ttf) format("truetype");
}
body {
  padding:0px;
  margin:0px;
  background: #ededed;
  background-image: url(./assets/image/gigihpack-background-image.png);
  background-repeat: no-repeat;
  background-size: 90rem;
}
#app {
  font-family: "LondonMM","LondonTwo",Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a {
  text-decoration: none;
  font-weight: bold;
  color: #946431;
  font-size: 1rem;
  &.router-link-exact-active {
    color: #644627;
    text-decoration: none;
  }
}
a:hover{
  color: #875b2d;
}
#wrapper-content{
  position: relative;
  top: 86vh;
  height: auto;
  width: 100%;
  background: #ece8df;
  border-radius: 1rem 1rem 0rem 0rem;
}
.wrapper-btn-wa-fixed{
  position: fixed;
  z-index: 600;
  left: auto;
  right: 2rem;
  bottom: 2rem;
  filter: drop-shadow(0px 1px 1px #000);
  transition: 300ms;
  .it-icon{
      font-size: 1.8rem;
  }
}

//animation
.slide-fade-enter-active {
  transition: all .8s ease-out;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.slide-flip-enter-active {
  transition: all .8s ease-out;
}
.slide-flip-enter-from, .slide-flip-leave-to {
  transform: rotateX(90deg);
  opacity: 0;
}

@media only screen and (max-width: 800px) {
  body {
    background-image:none;
  }
}
</style>
