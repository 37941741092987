<template>
  <div class="it-modal-content" >
    <div class="signupmodal p--3">
        <h2>Sign In</h2>
        <it-input labelTop="Username" prefix-icon="account_circle" placeholder="Unique Username" />
        <it-input labelTop="Password" prefix-icon="lock" type="password" placeholder="Must have at least 6 characters" />
        <div class="signupmodal-wrap-checkbox">
            <it-checkbox v-model="signUpCheckBox" label="Remember Username and Password" />
        </div>
        <it-button block size="big" type="primary" @click="$Message.warning({ text: 'No API Services' }), (signinModal = false)">Sign In</it-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ItemSignIn',
  data () {
    return {
      signUpCheckBox: false,
      agreedTOS: false
    }
  }
}
</script>
<style lang="scss">
.flex-1{
    float: left;
    margin-right: 1rem;
}
.it-input-label{
    padding-top: 15px;
}
.signupmodal-wrap-checkbox{
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
}
</style>
