<template>
  <header :class="{'header-scrolled' : showNavbarScrolled}" >
    <router-link to="/" @click="pageChanged"><img alt="gigihpack logo" src="../assets/gigihpack-logo.png"></router-link>
    <nav class="left-nav">
      <ul>
      </ul>
    </nav>
    <nav class="right-nav">
      <!-- <it-icon class="btn-outer-search" name="search" outlined color="#644627" v-show="statusSearch" @click="searchClicked" /> -->
      <ul>
        <!-- <li><it-input @blur="searchBlured" v-show="!statusSearch" v-model="searchValue" placeholder="Seach" suffix-icon="search" ref="headerSearch" /></li>
        <li><it-button @click="signInModal = true" type="primary" >Sign In</it-button></li>
        <li><it-button @click="signUpModal = true" type="primary" outlined >Sign Up</it-button></li> -->
        <li><router-link to="/" @click="pageChanged"><it-button type="primary" text>Home</it-button></router-link></li>
        <li><router-link to="/about" @click="pageChanged"><it-button type="primary" text>
          <span v-if="$store.state.lang == 'IN'" >Tentang Kami</span>
          <span v-if="$store.state.lang == 'EN'" >About Us</span>
        </it-button></router-link></li>
        <li><router-link to="/catalogue" @click="pageChanged"><it-button type="primary" text>
          <span v-if="$store.state.lang == 'IN'" >Produk</span>
          <span v-if="$store.state.lang == 'EN'" >Product</span>
        </it-button></router-link></li>
        <li><router-link to="/contact" @click="pageChanged"><it-button type="primary" text>
          <span v-if="$store.state.lang == 'IN'" >Hubungi Kami</span>
          <span v-if="$store.state.lang == 'EN'" >Call Us</span>
        </it-button></router-link></li>
        <li><it-toggle round v-model="languageValue" :options="['IN', 'EN']"/></li>
      </ul>
    </nav>
    <item-burger-button class="burger-btn" @page-changed="pageChanged" />
  </header>
  <it-modal v-model="signUpModal">
    <item-sign-up />
  </it-modal>
  <it-modal v-model="signInModal">
    <item-sign-in />
  </it-modal>
</template>
<script>
import ItemSignUp from './item/ItemSignUp.vue'
import ItemSignIn from './item/ItemSignIn.vue'
import ItemBurgerButton from './item/ItemBurgerButton.vue'

export default {
  name: 'AppHeader',
  components: {
    ItemSignUp,
    ItemSignIn,
    ItemBurgerButton
  },
  data () {
    return {
      statusSearch: true,
      searchValue: '',
      signUpModal: false,
      signInModal: false,
      showNavbarScrolled: false,
      languageValue: this.$store.state.lang
    }
  },
  methods: {
    searchClicked () {
      this.statusSearch = false
      this.$nextTick(() => {
        this.focusInputSearch()
      })
    },
    searchBlured () {
      this.statusSearch = true
    },
    focusInputSearch () {
      this.$refs.headerSearch.$el.children[1].children[0].children[0].focus()
    },
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      const contentElement = document.getElementById('wrapper-content')
      if (currentScrollPosition < 0) {
        return
      }
      this.showNavbarScrolled = currentScrollPosition >= contentElement.offsetTop - 110
    },
    pageChanged () {
      this.$emit('page-changed')
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  watch: {
    languageValue (newLanguage) {
      this.$store.state.lang = newLanguage
      localStorage.language = newLanguage
    }
  }
}
</script>
<style lang="scss">
  header {
    z-index: 500;
    width: 100%;
    position: fixed;
    display: flex;
    background-color: #fff;
    border-radius: 0px 0px 1rem 1rem;
    padding: 1rem 0rem 0rem 0rem;
    box-shadow: 0px 0px 1px #000;
    transition: 1s;
    p {
      margin-left: 1rem;
    }
    img {
      margin-left: 8rem;
      margin-top: -.5rem;
      width: 4rem;
      -webkit-filter:drop-shadow(0px 0px 0px rgba(100,70,39, 40%));
      filter: drop-shadow(0px 0px 0px rgba(100,70,39, 40%));
    }
  }
  .header-scrolled{
    box-shadow: 0px 0px 3px #000;
    transition:1s;
  }
  .left-nav {
    font-family: "LondonTwo";
    margin-right: auto;
    margin-left:-10px;
    ul {
      list-style: none;
    }
    ul li {
      display: inline-flex;
      margin-left: .5rem;
    }
  }
  .right-nav {
    font-family: "LondonTwo";
    margin-left: auto;
    margin-right: 8rem;
      color: rgb(100,70,39);
    ul {
      list-style: none;
      color: rgb(100,70,39);
    }
    ul li {
      display: inline-flex;
      margin-left: .4rem;
      color: rgb(100,70,39);
    }
    .btn-outer-search{
      color: rgb(100,70,39);
      float: left;
      font-size: 2rem;
      padding-top: 1rem;
      margin-left: 0.4rem;
      cursor: pointer;
    }
    .it-input-prefix-wrapper{
      margin-top: -10px;
    }
    .it-input-wrapper{
      top: 0.1rem;
    }
  }
  .burger-btn{
    display: none;
    transition: 1s;
  }
  .carousel__pagination{
    margin-left: -3rem;
  }

  @media only screen and (max-width: 785px) {
    header{
      nav{
        display: none;
      }
      .burger-btn{
        display: block;
        margin-right: 3rem;
        margin-left: auto;
      }
      .header-scrolled img{
        margin-top: -0.5rem;
        margin-bottom: 0.5rem;
      }
      img {
        width: 4rem;
        margin-top: -.5rem;
        margin-bottom: 0.8rem;
        margin-left: 2rem;
      }
    }
  }
</style>
