<template>
  <div class="it-modal-content" >
    <div class="signupmodal p--3">
        <h2>Sign Up</h2>
        <it-input labelTop="Email" prefix-icon="email" type="email" placeholder="yourmail@gmail.com" />
        <div class="signupmodal-wrap-inputs flex flex-row" style="display:flex;">
          <div class="flex-1">
            <it-input style="flex:1" labelTop="First Name" placeholder="John" />
          </div>
          <div class="flex-1">
            <it-input style="flex:1" labelTop="Second Name" placeholder="Doe" />
          </div>
        </div>
        <it-input labelTop="Username" prefix-icon="account_circle" placeholder="Unique Username" />
        <it-input labelTop="Password" prefix-icon="lock" type="password" placeholder="Must have at least 6 characters" />
        <div class="signupmodal-wrap-checkbox">
            <it-checkbox v-model="signUpCheckBox" label="I agree to our Terms of Service" />
            <it-button text type="primary" block @click="nestedModal = true" >Read Terms of Service</it-button>
        </div>
        <it-button block size="big" type="primary" @click="$Message.warning({ text: 'No API Services' }), (signupModal = false)">Sign Up</it-button>
    </div>
  </div>
  <it-modal v-model="nestedModal">
      <template #body>
          <div class="signupmodal p-3">
              <h2>Terms of Service</h2>
              <p class="p-terms-condition">
                  Terms of service (also known as terms of use and terms and conditions, commonly abbreviated as TOS or ToS, ToU or T&C) are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service.[1] Terms of service can also be merely a disclaimer, especially regarding the use of websites. Vague language and lengthy sentences used in the terms of use have brought concerns on customer privacy and raised public awareness in many ways.
                  <br />
                  Terms of service (also known as terms of use and terms and conditions, commonly abbreviated as TOS or ToS, ToU or T&C) are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service.[1] Terms of service can also be merely a disclaimer, especially regarding the use of websites. Vague language and lengthy sentences used in the terms of use have brought concerns on customer privacy and raised public awareness in many ways.
                  <br />
                  Terms of service (also known as terms of use and terms and conditions, commonly abbreviated as TOS or ToS, ToU or T&C) are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service.[1] Terms of service can also be merely a disclaimer, especially regarding the use of websites. Vague language and lengthy sentences used in the terms of use have brought concerns on customer privacy and raised public awareness in many ways.
              </p>
              <div class="signupmodal-wrap-checkbox">
                  <it-button block size="big" type="primary" @click="(nestedModal = false), (agreedTOS = true)">I agree</it-button>
              </div>
          </div>
      </template>
  </it-modal>
</template>
<script>
export default {
  name: 'ItemSignUp',
  data () {
    return {
      nestedModal: false,
      signUpCheckBox: false,
      agreedTOS: false
    }
  }
}
</script>
<style lang="scss">
.flex-1{
    float: left;
    margin-right: 1rem;
}
.it-input-label{
    padding-top: 15px;
}
.it-checkbox-wrapper{
    padding-top: 5px;
    padding-bottom: 5px;
}
.it-btn{
    margin-bottom: 15px;
}
.p-terms-condition{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 18rem;
    margin: 16px;
}
</style>
