<template>
<div class="wrapper-banner">
    <img class="paper" :class="{'paper1': paper1}" src="../assets/image/paper02.jpeg" />
    <img class="paper" :class="{'paper2': paper2}" src="../assets/image/paper01.jpeg" />
    <img class="paper" :class="{'paper3': paper3}" src="../assets/image/paper03.jpeg" />
    <img class="paper" :class="{'paper4': paper4}" src="../assets/image/paper01.jpeg" />
    <div class="banner-left">
        <div class="tag-line" :class="{'tag-line-active': tagLineActive}">
            Giving shine to your product
        </div>
        <div class="tag-line-two" :class="{'tag-line-two-active': tagLineTwoActive}">
            <span> The best quality, best time, </span><br /><span> and best product. </span>
        </div>
        <div class="btn-tag-line" :class="{'btn-tag-line-active': btnTagLineActive}" >
            <it-button class="fist-btn-tag-line" @click="orderNowModal = true" type="primary" size="big" >Order Now</it-button>
            <it-button @click="signInModal = true" type="primary" outlined size="big" >Sign In</it-button>
            <div id="wrapper-btn-wa" class="wrapper-btn-wa"><it-icon box box-color="#17954c" color="#fff" name="whatsapp" @click="openWa" /></div>
        </div>
    </div>
    <div class="banner-right" :class="{'banner-right-active': bannerRightActive}" >
        <div class="wrapper-image-banner">
            <img src="../assets/image/gigihpack-worker-printing.jpg" />
        </div>
    </div>
</div>
</template>
<script>
export default {
  name: 'AppBanner',
  data () {
    return {
      paper1: false,
      paper2: false,
      paper3: false,
      paper4: false,
      tagLineActive: false,
      tagLineTwoActive: false,
      btnTagLineActive: false,
      bannerRightActive: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.paper1 = true
    }, 600)
    setTimeout(() => {
      this.paper2 = true
    }, 400)
    setTimeout(() => {
      this.paper3 = true
    }, 200)
    setTimeout(() => {
      this.paper4 = true
    }, 700)
    setTimeout(() => {
      this.tagLineActive = true
    }, 800)
    setTimeout(() => {
      this.tagLineTwoActive = true
    }, 900)
  },
  methods: {
    openWa () {
      window.open('https://wa.me/6281383065737/?text=Hallo%20GigihPack%2C%20saya%20ada%20kebutuhan%20food%20packaging%20bisa%20dibantu%3F', '_blank')
    }
  }
}
</script>
<style lang="scss">
.wrapper-banner{
    position: fixed;
    z-index: 0;
    width: 100vw;
    height: auto;
    max-height: 100vh;
    img{
        position: absolute;
        right: auto;
        left: 0px;
        box-shadow: 1px 5px 6px #000;
    }
    .banner-left{
        position: absolute;
        width: 50%;
        height: 100vh;
        margin:0px;
        left:0px;
        right: auto;
        z-index: 405;
        padding-top: 37vh;
        .tag-line{
            opacity: 0;
            color: #fff;
            font-family: "LondonTwo";
            font-size: 4.3rem;
            text-align: left;
            width: 80%;
            line-height: 3.5rem;
            padding-left: 27%;
            text-shadow: 0 3px 2px #838140;
            transition: 1s;
        }
        .tag-line-active{
            opacity: 1;
            padding-left: 17%;
        }
        .tag-line-two{
            opacity: 0;
            color: #fff;
            font-family: "LondonTwo";
            font-size: 1.6rem;
            text-align: left;
            width: 80%;
            padding-left: 28%;
            padding-bottom: 3.5vh;
            text-shadow: 0 2px 1px #805429;
            line-height: 2rem;
            margin-top: 2vh;
            transition: 1s;
        }
        .tag-line-two-active{
            opacity: 1;
            padding-left: 18%;
        }
        .btn-tag-line{
            opacity: 0;
            float: left;
            margin-left: 28%;
            width: 100%;
            transition: 1s;
        }
        .btn-tag-line-active{
            opacity: 1;
            margin-left: 18%;
        }
        .it-btn--big{
            float: left;
            margin-right: 2%;
        }
        .wrapper-btn-wa{
        position: absolute;
        margin-left: 16rem;
        z-index: 440;
            .it-icon{
                font-size: 1.8rem;
            }
        }
    }
    .paper{
        transform: rotate(0deg);
        opacity: 0;
        width: 80rem;
        left: -48.5rem;
        top: -10rem;
        z-index: 399;
        transition: 1.5s;
    }
    .paper1{
        opacity: .4;
        transform: rotate(32deg);
        z-index: 404;
        width: 80rem;
        left: -48.5rem;
        top: -10rem;
    }
    .paper2{
        opacity: .4;
        transform: rotate(36deg);
        z-index: 403;
        width: 76rem;
        left: -58rem;
        top: -12rem;
    }
    .paper3{
        opacity: .4;
        transform: rotate(43deg);
        z-index: 402;
        width: 78rem;
        left: -40rem;
        top: 0rem;
    }
    .paper4{
        opacity: .4;
        transform: rotate(29deg);
        z-index: 401;
        width: 76rem;
        left: -63rem;
        top: -18rem;
    }
    .banner-right{
        opacity: 0;
        position: absolute;
        width: 45%;
        height: 100vh;
        margin:0px;
        left:auto;
        right:0px;
        padding-top:14vh;
        transition: 1500ms;
        .wrapper-image-banner{
            display: inline-block;
            width: 21rem;
        }
        img{
            position: absolute;
            box-shadow: none;
            width: 112%;
            left: -10%;
        }
    }
    .banner-right-active{
        opacity: 1;
        padding-top:20vh;
    }
}
@media only screen and (max-width: 1000px) {
    .wrapper-banner{
        .banner-right{
            display: none;
        }
        .banner-left{
            width: 78%;
            .tag-line{
                padding-left: 20%;
            }
            .tag-line-two{
                padding-left: 21%;
            }
            .btn-tag-line{
                margin-left: 21%;
            }
            .tag-line-active{
                padding-left: 10%;
            }
            .tag-line-two-active{
                padding-left: 11%;
            }
            .btn-tag-line-active{
                margin-left: 11%;
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .wrapper-banner{
        .banner-left{
            width: 100%;
        }
    }
}
@media only screen and (max-width: 350px) {
    .wrapper-banner{
        .banner-left{
            padding-top: 19vh;
            .wrapper-btn-wa {
                margin-left: 15.3rem;
            }
            .tag-line-two {
                font-size: 1rem;
            }
            .tag-line {
                font-size: 3.3rem;
            }
        }
    }
}
</style>
