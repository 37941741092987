<template>
  <div class="notfound">
    <h1> Page Not Found 404 </h1>
  </div>
</template>

<script>

export default {
  name: 'Notfound'
}
</script>
<style scoped>
  .notfound{
    position: relative;
    min-height: 100vh;
    width: 100%;
  }
</style>
