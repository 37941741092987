<template>
  <div class="wrapper-group-button">
    <it-button-group vertical>
      <router-link to="/" @click="this.$emit('menu-clicked')" class="router-link-gb" ><it-button><router-link to="/">Home</router-link></it-button></router-link>
      <router-link to="/about" @click="this.$emit('menu-clicked')" class="router-link-gb" ><it-button><router-link to="/about">
        <span v-if="$store.state.lang == 'IN'" >Tentang Kami</span>
        <span v-if="$store.state.lang == 'EN'" >About Us</span>
      </router-link></it-button></router-link>
      <router-link to="/catalogue" @click="this.$emit('menu-clicked')" class="router-link-gb" ><it-button><router-link to="/catalogue">
                  <span v-if="$store.state.lang == 'IN'" >Produk</span>
                  <span v-if="$store.state.lang == 'EN'" >Product</span>
      </router-link></it-button></router-link>
      <router-link to="/contact" @click="this.$emit('menu-clicked')" class="router-link-gb" ><it-button><router-link to="/contact">
                  <span v-if="$store.state.lang == 'IN'" >Hubungi Kami</span>
                  <span v-if="$store.state.lang == 'EN'" >Call Us</span>
      </router-link></it-button></router-link>
      <router-link to="/#" @click="changeLanguage('IN')" class="router-link-gb" ><it-button><router-link to="/#">Indonesia</router-link></it-button></router-link>
      <router-link to="/#" @click="changeLanguage('EN')" class="router-link-gb" ><it-button><router-link to="/#">English</router-link></it-button></router-link>
    </it-button-group>
  </div>
</template>
<script>
export default {
  name: 'ItemBurgerButton',
  props: {
    groupBtnActive: Boolean
  },
  methods: {
    changeLanguage (newLang) {
      this.$store.state.lang = newLang
      localStorage.language = newLang
      this.$emit('menu-clicked')
    }
  }
}
</script>
<style scoped>
.wrapper-group-button{
  position: fixed;
  width: 100%;
  z-index: -1;
  background-color: #fff;
  height: 100vh;
  top: 0px;
}
.wrapper-group-button .it-button-group{
  margin-top: 16vh;
}
.wrapper-group-button .it-btn{
  margin-bottom: 0px;
  line-height: 3rem;
}
.router-link-gb{
  width: 100%;
}
</style>
